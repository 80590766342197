import { FC } from "react";
import { Pressable } from "react-native";
import { Colors, Text, View } from "react-native-ui-lib";
import { gradientPink, styles } from "../styles";
import LinearGradient from "react-native-linear-gradient";
import { MaterialIcons } from '@expo/vector-icons';

export type ButtonProps = {
    onPress: Function,
    label?: string,
    icon?: any
}

/**
 *
 * @returns Primary button component.
 */
export const ButtonPrimary: FC<ButtonProps> = (
    {
        onPress,
        label,
    }
) => {

    return (
        <Pressable onPress={() => onPress()} >
            <LinearGradient
                colors={gradientPink}
                style={[styles.buttonShadow, styles.buttonPrimary]}
            >
                <Text style={styles.buttonPrimaryText} center>
                    {label}
                </Text>
            </LinearGradient>
        </Pressable>
    )

}

/**
 *
 * @returns Button component with icon.
 */
export const IconButton: FC<ButtonProps> = ({ onPress, icon }) => {

    return (
        <Pressable onPress={() => onPress()} >
            {icon}
        </Pressable>
    )

}

/**
 * A lightweight button component that renders a text label and an optional icon.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {() => void} props.onPress - The function to call when the button is pressed.
 * @param {string} props.label - The text label to display on the button.
 * @param {React.ReactNode} [props.icon] - An optional icon to display alongside the label.
 * @returns {JSX.Element} The rendered button component.
 */
export const LigthweightButton: FC<ButtonProps> = ({ onPress, label, icon }) => {

    return (
        <Pressable onPress={() => onPress()} >
            <View row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <Text textSecondary>
                    {label}
                </Text>
                {icon}
            </View>
        </Pressable>
    )

}

/**
 *
 * @returns Log In button.
 */
export const LogInButton: FC<ButtonProps> = ({ onPress }) => {

    return (
        <IconButton
            onPress={onPress}
            icon={<MaterialIcons name="login" size={30} color={Colors.iconSecondary} />}
        />
    )

}